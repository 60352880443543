import * as React from 'react';

import { GCMSCourses } from 'shared/CourseLanding/CoursesIelts';
import { GCMSHeader } from 'shared/IeltsCourse/Header';
import IeltsRoadmap from 'shared/IeltsCourse/IeltsRoadmap';

import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import { FixedTopBarScholarshipScheduleNOffline } from 'shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';

const CourseLandingPage = ({ location }) => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        slug={location.pathname}
        h1="Khóa học IELTS"
        hidePostfix
        featureImg="RWSvaIZT5x9SgNAaCtwM"
        title="Các khóa học IELTS hàng đầu, Cam Kết Đầu Ra tại DOL ENGLISH"
        description="Khóa học luyện thi ielts cấp tốc uy tín, khóa học ielts cơ bản cho người mới bắt đầu, khóa nâng cao cho các band điểm 6.0+ và các khóa luyện thi online"
      />
      <FixedTopBarScholarshipScheduleNOffline />
      <GCMSHeader />
      <GCMSCourses />
      <IeltsRoadmap />
    </MainLayout>
  );
};

export default React.memo(CourseLandingPage);
